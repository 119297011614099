var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"channel-settings"},[_c('a-card',[_c('switch-input',{staticClass:"mt-0",attrs:{"setup":{
        'func': _vm.channelConfigSetup,
        'args': {
          'key': 'kick_deleted_accounts',
          'tags': [{ color: 'rgba(var(--a-primary), 1)', text: 'Beta' }],
          'hasAnchor': true,
        },
      }}}),_c('switch-input',{attrs:{"setup":{
        'func': _vm.channelConfigSetup,
        'args': {
          'key': 'pm_reports',
          'hasAnchor': true,
        },
      }}}),_c('switch-input',{attrs:{"setup":{
        'func': _vm.channelConfigSetup,
        'args': {
          'key': 'delete_channel_service_messages',
          'hasAnchor': true,
        },
      }}})],1),(_vm.actionSet)?_c('highlight-anchor',{staticClass:"mt-5",attrs:{"has-anchor-icon":"","anchor-key":"goodbuy_action"}},[_c('activation-action-data',{attrs:{"activation-action":_vm.$store.state.channelsState.activeChannel.config.goodbuy_action,"flow-options":_vm.resolveFlowSelectOption,"get-flow-preview":function (key) { return ("https://api.presscode.app/thumbnail/flow/" + key); },"empty-flow-button-callback":_vm.gotoFlows,"board":_vm.$store.state.boardsState.activeBoard,"buttons":_vm.newPmButtons,"placeholders":_vm.botFeedbackPlaceholders,"editor-buttons-help-view":_vm.$t('select_editor_button_help_message'),"prefix":"goodbuy_action_"},on:{"update:activationAction":function($event){return _vm.$set(_vm.$store.state.channelsState.activeChannel.config, "goodbuy_action", $event)},"update:activation-action":function($event){return _vm.$set(_vm.$store.state.channelsState.activeChannel.config, "goodbuy_action", $event)}},scopedSlots:_vm._u([{key:"no-action-info",fn:function(){return [_c('a-alert',{attrs:{"show-icon":""}},[_c('span',{attrs:{"slot":"message"},domProps:{"innerHTML":_vm._s(_vm.$t('field_goodbuy_action_alert', [_vm.$store.getters.activeBoard.title]))},slot:"message"})])]},proxy:true}],null,false,69399265)})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }